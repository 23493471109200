

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/garde-enfants-partagee-exclusive-erreurs-eviter-avocat.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost37 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Gardes des enfants : les erreurs à éviter au moment de la séparation! - Soumissions Avocat"
        description="Avec le nombre élevé de ruptures et de divorce au Québec, il n’est pas surprenant que les cours soient encombrées par des poursuites en divorce, en séparation de corps et, inévitablement, en demande de garde d’enfants. Ces derniers sont les toujours les innocents de l’histoire qui écopent lorsque les relations LIRE PLUS"
        image={LeadImage}>
        <h1>Gardes des enfants : les erreurs à éviter au moment de la séparation!</h1>

					
					
						<p>Avec le nombre élevé de ruptures et de divorce au Québec, il n’est pas surprenant que les cours soient encombrées par des poursuites en divorce, en séparation de corps et, inévitablement, en demande de garde d’enfants. Ces derniers sont les toujours les innocents de l’histoire qui écopent lorsque les relations entre les parents se détériorent. D’ailleurs, la loi en fait sa priorité de protéger leurs intérêts avant ceux des parents.</p>
<p><StaticImage alt="services-avocat-droit-famille-demande-garde-enfant-pension" src="../images/garde-enfants-partagee-exclusive-erreurs-eviter-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Toutefois, dans des contextes aussi émotionnels, il peut être tentant de commettre des actes impulsifs pour faire ce que l’on croit être dans le meilleur intérêt des enfants. Pour vous éviter de creuser votre propre tomber et pour obtenir les meilleures chances d’avoir le type de garde que vous souhaitez, voici les principales erreurs à éviter lors d’un rel recours!</p>
<h2>Quand est-ce qu’une demande de garde prend place?</h2>
<p>Peu importe que les parents soient en union de fait, unis par les liens du mariage ou de l’Union civile, il faudra, au moment de la séparation, procéder à une demande de garde. Cela visera à déterminer le principal lieu de résidence des enfants, les droits d’accès des parents et le montant de la pension alimentaire à payer le cas échéant.</p>
<p>Il n’y a pas de modèle préfabriqué d’entente de garde partagée entre les parents. Ceux-ci sont libres de fixer les modalités qui leur conviennent, du moment que le tout est fait d’un commun accord, mais surtout dans le meilleur intérêt de l’enfant.</p>
<p>Les juges de la Cour du Québec, chambre de la famille, peuvent d’ailleurs être saisis d’un désaccord entre les parents lorsqu’ils ne s’entendent pas sur le type de garde, les droits d’accès, etc. Cette même cour offre également des séances de médiation familiale gratuites.</p>
<h2>Les différents types de gardes et les critères d’attribution</h2>
<p>Bien que les parents puissent fixer eux-mêmes les conditions de garde, il n’est pas rare que les juges de la Cour du Québec soient appelés à les aider ou simplement à trancher une impasse. En se fiant à des critères bien établis dans la jurisprudence, le juge examinera la situation particulière des parties pour stipuler les conditions. Il choisira parmi les gardes partagées et exclusives en se fiant aux critères qui suivent.</p>
<p><strong>Garde exclusive vs. partagée : </strong>On qualifiera la garde comme étant partagée lorsqu’un parent passera entre 40% et 60% du temps avec l’enfant. Du moment que le temps de garde dépasse des ratios, il s’agira d’une garde qualifiée comme étant exclusive. Les ratios légaux de garde se calculent toutefois en jour et un parent ne peut pas passer plus de 219 jours ni moins de 146 pour être considéré comme étant en garde partagée.</p>
<p><strong>
Stabilité : </strong>Le principe directeur du droit de la famille est la préséance de l’intérêt de l’enfant sur les intérêts des parents et autres intéressés. La jurisprudence a révélé a maintes reprises que la stabilité est un facteur déterminant pour déterminer lequel des parents obtiendra la garde. Ainsi, un parent incapable de favoriser un tel environnement pourrait ne pas se faire attribuer la garde partagée et même voir l’autre parent obtenir une garde exclusive.</p>
<p><strong>
Présence disponible :</strong> Dans une société où les parents sont de plus en plus impliqués dans la vie de leurs enfants, la cour veut voir des parents présents et disponibles. Ce facteur en est un qui se calcule en temps, mais également en qualité. Le juge voudra voir lequel des deux parents offre un environnement émotionnel et affectif le plus favorable; il se peut qu’aussi bien l’un que l’autre soient à la hauteur.</p>
<p><strong>
Communication entre parents : </strong>Pour ordonner la mise en place d’une garde partagée, le juge vérifiera avant tout si les parents communiquent efficacement. Comme ce genre d’arrangement nécessite une coordination efficace entre les parents, un juge ne la mettra pas en place si ceux-ci sont incapables d’être dans la même pièce et que ce type de garde risquait d’aliéner le bien-être des enfants.</p>
<p><strong>
L’âge des enfants :</strong> Plus l’enfant grandit, plus le juge accorde de poids à ses opinions, sa volonté et son désir de vivre avec un parent plutôt que l’autre. Évidemment, le juge analyse le pouvoir de discernement de l’enfant et sa maturité et n’accepte pas aveuglément la volonté exprimée.</p>
<p><strong>
Lieux du domicile :</strong> Si un parent déménage loin de la ville où les enfants ont été élevés et où l’autre conjoint demeure toujours, il se peut fort bien que le juge n’attribue pas la garde à ce dernier. Cela ne favoriserait ni la stabilité ni l’intérêt premier de l’enfant.</p>
<h2>Les mythes à ne pas croire à propos de la garde des enfants</h2>
<p>Dans le monde du droit de la famille, il n’y a pas de place pour les mythes et les légendes. Ce sont des certitudes donc les enfants ont besoin et c’est ce que le système judiciaire vise à leur offrir. Distinguez le bon grain de l’ivraie avec ces mythes démystifiés!</p>
<p><StaticImage alt="mythes-garde-enfants-fonctionnement-juge-entente-avocat" src="../images/mythes-garde-enfants-fonctionnement-juge-entente-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Il n’y a aucune pension alimentaire à payer lorsque la garde est partagée également. Faux! </strong>Le principe de proportionnalité s’applique en matière de garde d’enfants. Cela implique que les parents contribuent aux dépenses de l’enfant en proportion de leurs moyens. Un parent qui gagne plus que l’autre pourrait donc être obligé de payer une pension à l’autre.</p>
<p><strong>
Les dépenses relatives aux besoins des enfants sont réparties également.</strong> <strong>Faux</strong>! En règle générale, les parents paient également les frais relatifs aux besoins essentiels de l’enfant. Toutefois, pour les dépenses extraordinaires et couteuses, le parent en moyens pourrait être contraint à payer plus. Les barèmes de pension alimentaire sont toujours applicables!</p>
<p><strong>
Le juge considère avant tout les besoins et préférences des parents en attribuant la garde de l’enfant. Faux! </strong>Les besoins des parents ont une importance très secondaire aux yeux du juge. C’est plutôt l’intérêt de l’enfant, ses besoins moraux et affectifs ainsi que ses besoins physiques qui guideront la décision du juge.</p>
<h2>Quelles sont les erreurs à éviter en matière de garde d’enfants?</h2>
<p>Un recours pour obtenir la garde des enfants ne devrait jamais viser la victoire, mais bien la solution qui répond le mieux aux besoins des enfants. Il est facile de perdre de vue cet objectif dans la quête de conditions de garde favorables. Un écart de conduite peut toutefois vous faire perdre gros, alors évitez les erreurs suivantes!</p>
<p><strong>Partir avec les enfants :</strong> Nous l’avons dit et nous le répétons, les enfants ont besoin d’un environnement stable et d’habitudes de vie constantes. Votre envie de quitter le nid familial avec eux vient peut-être de bonnes intentions, mais la loi en le voit pas ainsi. Vous les retirez d’un environnement sécuritaire et surtout sécurisant pour eux. C’est d’ailleurs la raison pour laquelle la Cour est compétente pour exiger qu’un parent quitte la maison lorsque la cohabitation avec l’ex-conjoint n’est pas envisageable; l’intérêt de l’enfant prime toujours.</p>
<p><strong>
Laisser les enfants à votre ex-conjoint : </strong>En confiant la garde à votre ex-conjoint, même de façon temporaire en attendant que la poussière redescende, vous créez un état de fait dans lequel les enfants bénéficient d’un environnement stable et propice. Le juge sera hésitant à modifier cette situation au moment de la demande de garde et vous pourriez vous retrouver dans une situation où l’accès à vos enfants est plus restreint que vous ne le souhaitez. Il est donc important de mettre en place un accord de garde clair!</p>
<p><strong>
Conclure une entente de garde verbale : </strong>Un contrat verbal, ce n’est jamais une bonne idée! Ça l’est encore moins lorsque l’objet de l’entente est l’attribution de la garde de vos enfants. Mettez toujours l’entente par écrit et demandez à un avocat de vous assister lors de l’élaboration et de la signature.</p>
<p><strong>
Ne pas modifier l’entente :</strong> Des enfants, ça grandit, ça évolue et les besoins de ceux-ci font la même chose. Il serait donc ridicule de maintenir en vigueur une entente déjà vieille de plusieurs années. Au fur et à mesure que la vie de vos enfants change, l’entente sur la garde devrait elle aussi s’adapter!</p>
<p><strong>
Agir sans consulter l’autre parent : </strong>Peu importe le type de garde attribuée, les parents conservent le plein exercice de l’autorité parentale, c’est-à-dire, le pouvoir de surveiller, d’éduquer et d’entretenir leurs enfants. Les décisions importantes doivent donc toujours être prises à deux. La cour est même compétente pour entendre les désaccords sur l’exercice de l’autorité parentale, alors ne faites pas cavalier seul!</p>
<p><strong>
Ignorer les volontés des enfants : </strong>Ce sont les parents qui fixent la garde pour assurer un bon fonctionnement familial après la séparation. Toutefois, comme les enfants sont les principaux acteurs de l’entente de garde, ne pas demander leur avis lorsqu’ils ont atteint un certain âge nuirait à la convivialité de la garde.</p>
<h2>Les services d’un avocat lors d’une demande de garde d’enfants</h2>
<p>Maintenant que vous êtes au courant du fonctionnement et des erreurs à éviter lors d’une dispute sur la garde des enfants, vous vous demandez surement comment un avocat peut vous aider à régler le tout dans la paix et l’harmonie. Voici donc les principaux services offerts par les avocats dans la province de Québec en droit de la famille.</p>
<p><StaticImage alt="services-avocat-droit-famille-demande-garde-enfant-pension" src="../images/services-avocat-droit-famille-demande-garde-enfant-pension.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Médiation familiale : </strong>Pour éviter de se rendre en cour, le système judiciaire du Québec offre des services tout à fait gratuits de médiation familiale. Ces séances visent à éviter que la tension monte et que les parties fassent valoir leur point dans un environnement qui vise la conciliation et non l’affrontement. Les avocats peuvent non seulement mener des séances de médiation, mais également assister les parties lors de telles séances.</p>
<p><strong>
Représentation des parties : </strong>Lorsque la cause portant sur la garde des enfants sera portée devant le tribunal, un avocat sera chargé de représenter les parties de part et d’autre de la dispute. Vous devriez assurément engager un avocat en droit de la famille, car les disputes dans ce domaine de droit sont uniques en leur genre.</p>
<p><strong>
Représentation de l’enfant :</strong> Dans des circonstances exceptionnelles, à la demande d’un parent, du juge ou de l’entant lui-même, ce dernier peut être représenté par un avocat lors d’une cause portée devant la cour. Cela arrivera notamment lorsque la relation entre les parents sera empreinte de mauvaise foi.</p>
<h2>Soumissions Avocat peut vous mettre en contact avec des avocats en droit de la famille dans votre région!</h2>
<p>Pour éviter de commettre une erreur qui pourrait vous faire perdre la garde partagée de vos enfants ou simplement pour négocier une entente de garde convenable, faites affaire avec un avocat en droit de la famille sans tarder! Ces professionnels du droit se spécialisent dans des causes telles que les demandes de garde et sauront comment faire valoir vos intérêts!</p>
<p><strong>Vous êtes prêt à mettre fin à votre dispute de garde? Vous n’avez qu’à remplir le formulaire au bas de la page et nous enverrons votre demande à 3 avocats sans délai!</strong></p>
<p><strong>C’est un service gratuit et sans engagement, alors rien ne sert d’attendre, faites affaire avec les meilleurs!</strong></p>
    </SeoPage>
)
export default BlogPost37
  